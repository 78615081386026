"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaptionConfig = void 0;
const typebox_1 = require("@sinclair/typebox");
const numerical_timestamp_json_1 = require("./numerical-timestamp-json");
/**
 * Defines the JSON serialized shape of `caption_config` data records.
 */
exports.CaptionConfig = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    uri: typebox_1.Type.String(),
    language: typebox_1.Type.String(),
    slug: typebox_1.Type.String(),
    description: typebox_1.Type.Optional(typebox_1.Type.Union([typebox_1.Type.String(), typebox_1.Type.Null()])),
    assetId: typebox_1.Type.String(),
    ...numerical_timestamp_json_1.TimestampsJson.properties,
});
