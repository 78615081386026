"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stream = exports.StreamMetadata = void 0;
const typebox_1 = require("@sinclair/typebox");
const numerical_timestamp_json_1 = require("./numerical-timestamp-json");
exports.StreamMetadata = typebox_1.Type.Union([
    // type === mux
    typebox_1.Type.Object({ muxPlaybackId: typebox_1.Type.String() }),
    // type === mediaconvert
    typebox_1.Type.Object({}),
]);
/**
 * Defines the JSON serialized shape of `stream` data records.
 */
exports.Stream = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    status: typebox_1.Type.String(),
    type: typebox_1.Type.String(),
    active: typebox_1.Type.Boolean(),
    transcodeId: typebox_1.Type.String(),
    metadata: exports.StreamMetadata,
    assetId: typebox_1.Type.String(),
    ...numerical_timestamp_json_1.TimestampsJson.properties,
});
